import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import "../scss/pages/analytics.scss";

const AnalyticsCard = ({ title, image, onclick }) => (
  <div class="column">
    <div
      className="card analytics-card"
      onClick={onclick}
    >
      <center>
        <p className="has-text-centered">{title}</p>
        <div className="card-image">
          <figure className="image" style={{ cursor: "pointer" }}>
            <GatsbyImage image={image.gatsbyImageData} alt={title} />
          </figure>
        </div>
      </center>
    </div>
  </div>
);

export default AnalyticsCard;
