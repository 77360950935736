export const pages = [
  {
    title: "All Labs",
    image: "summary.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/5fLPB",
  },
  {
    title: "Experiments",
    image: "experiments.jpg",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/IKsQB",
  },
  {
    title: "Amrita",
    image: "amrita.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/M1UPB",
  },
  {
    title: "COEP",
    image: "coep.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/d3UPB",
  },
  {
    title: "Dayalbagh",
    image: "dayalbagh.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/O3UPB",
  },
  {
    title: "IIITH",
    image: "iiith.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/16SPB",
  },
  {
    title: "IITB",
    image: "iitb.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/0mTPB",
  },
  {
    title: "IITD",
    image: "iitd.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/1oTPB",
  },
  {
    title: "IITG",
    image: "iitg.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/fnTPB",
  },
  {
    title: "IITK",
    image: "iitk.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/0mTPB",
  },
  {
    title: "IITR",
    image: "iitr.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/y3UPB",
  },
  {
    title: "NITK",
    image: "nitk.png",
    dataSrc:
      "https://datastudio.google.com/embed/reporting/1bVjKkAw-e617LmNE1v_WPdIByVRz2waa/page/OoTPB",
  },
];
