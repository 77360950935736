import React, { useState } from "react";
import { graphql } from "gatsby";
import AnalyticsCard from "../components/analytics-card";
import Layout from "../layout/layout";


import { pages } from "../data/analytics/config.js";

const Analytics = ({ data: images }) => {
  const [activePageIdx, setActivePageIdx] = useState(0);
  const [dropdownActive, setDropdownActive] = useState(false);

  const toggleDropdown = () => setDropdownActive(!dropdownActive);

  const activatePage = (idx) => {
    setActivePageIdx(idx);
    toggleDropdown();
  };

  const imageMap = {};

  images.allFile.nodes.forEach((element) => {
    imageMap[element.fullName] = element.childImageSharp;
  });

  return (
    <Layout>
      <div
        className={
          dropdownActive
            ? "dropdown is-hidden-desktop is-active mt-4 ml-4"
            : "dropdown is-hidden-desktop mt-4 ml-4"
        }
      >
        <div className="dropdown-trigger">
          <button
            className="button"
            aria-haspopup="true"
            aria-controls="dropdown-menu"
            onClick={toggleDropdown}
          >
            <span>{pages[activePageIdx].title}</span>
            <span className="icon is-small">
              <i className="fas fa-angle-down" aria-hidden="true"></i>
            </span>
          </button>
        </div>
        <div className="dropdown-menu" id="dropdown-menu" role="menu">
          <div className="dropdown-content">
            {pages.map((page, idx) => (
              <a className="dropdown-item" onClick={() => activatePage(idx)}>
                {page.title}
              </a>
            ))}
          </div>
        </div>
      </div>
      <main>
        <div className="columns">
          <aside className="menu mt-4 column is-narrow is-one-quarter-fullhd is-one-quarter-widescreen is-one-third-tablet is-hidden-touch">
            <ul className="menu-list columns is-multiline is-centered">
              {pages.map((page, idx) => (
                <li className="column is-narrow">
                  <AnalyticsCard
                    key={idx}
                    title={page.title}
                    image={imageMap[page.image]}
                    onclick={() => setActivePageIdx(idx)}
                  />
                </li>
              ))}
            </ul>
          </aside>
          <div className="column mt-4">
            <iframe
              id="IframeLink"
              src={pages[activePageIdx].dataSrc}
              width="100%"
              height="1105"
              frameBorder="0"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </main>
    </Layout>
  );
};

export default Analytics;

// export const analyticsImageQuery = graphql`{
//   allFile(
//     filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "analytics"}}
//   ) {
//     nodes {
//       fullName: base
//       childImageSharp {
//         gatsbyImageData(height: 100, width: 100, placeholder: TRACED_SVG, layout: FIXED)
//         fluid(maxWidth: 100) {
//           ...GatsbyImageSharpFluid_tracedSVG
//         }
//       }
//     }
//   }
// }
// `;

export const analyticsImageQuery = graphql`{
  allFile(
    filter: {sourceInstanceName: {eq: "images"}, relativeDirectory: {eq: "analytics"}}
  ) {
    nodes {
      fullName: base
      childImageSharp {
        gatsbyImageData(height: 100, width: 100, placeholder: TRACED_SVG, layout: FIXED)
      }
    }
  }
}
`;
